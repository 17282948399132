import React from 'react';
import Box from '@mui/material/Box';
import SingleBlockPage from '../../../layouts/SingleBlockPage';
import LoginForm from '../../LoginForm';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import { RoutePageProps } from '../../../common/hooks/useNamedRoute';

const LoginPage: React.FunctionComponent<RoutePageProps> = () => {
    const { consumerName } = useConfig();

    return <SingleBlockPage
        title={ `${ consumerName }` }
        withActions={ true }
    >
        <Box padding="100px 0">
            <LoginForm isForIntegration={ false }/>
        </Box>
    </SingleBlockPage>;
};

export default LoginPage;
